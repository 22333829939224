.topHeader {
  background: #fff;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.05);
  padding: 20px 0;
}
.topHeader .topLogo a {
  display: inline-block;
}
.topHeader .topLogo a img {
  max-height: 60px;
}
.topHeader .topHRightNav .username {
  white-space: nowrap;
  max-width: 160px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
}
.ant-popover.notificationPopOver {
  max-width: 400px;
}
@media (max-width: 480px) {
  .ant-popover.notificationPopOver {
    max-width: 90%;
  }
}
.ant-popover.notificationPopOver.ant-popover-placement-bottomRight {
  padding-top: 15px;
}
.ant-popover.notificationPopOver.ant-popover-placement-bottomRight .ant-popover-arrow {
  border-width: 10px;
  right: 5px;
}
.ant-popover.notificationPopOver.ant-popover-placement-bottomRight .ant-popover-inner {
  margin-right: -15px;
}
@media (max-width: 600px) {
  .ant-popover.notificationPopOver.ant-popover-placement-bottomRight .ant-popover-inner {
    margin-right: -4px;
  }
}
.ant-popover.notificationPopOver .ant-popover-inner-content {
  max-height: 400px;
  overflow-y: auto;
}
.ant-popover.notificationPopOver .ant-popover-inner-content .ant-card-body {
  padding: 10px;
}
.ant-popover.notificationPopOver .ant-popover-inner-content .ant-card-body .notiTitle {
  font-size: 1.0714em;
  font-weight: 500;
}
.profileDropdown .ant-menu-item {
  padding: 0;
}
.profileDropdown .ant-menu-item a {
  padding: 0 20px;
  color: #000;
  font-size: 1.0714em;
  display: block;
}
.profileDropdown .ant-menu-item.ant-menu-item-active {
  color: #fff;
}
.profileDropdown .ant-menu-item.ant-menu-item-active a {
  background-color: #000000;
  color: #fff;
}
.topHRightNav .ant-dropdown-trigger {
  cursor: pointer;
}
.notifications {
  position: relative;
  cursor: pointer;
}
.notifications .badgeNot {
  position: absolute;
  height: 12px;
  width: 12px;
  right: -2px;
  top: -2px;
  background: #0ba942;
  border: 2px solid #fff;
  border-radius: 50%;
}
.unseen {
  position: absolute;
  height: 10px;
  width: 10px;
  left: 1px;
  top: 1px;
  background: #0ba942;
  border-radius: 50%;
}
@media (max-width: 1199px) {
  .topMenu {
    position: relative;
    z-index: 11;
  }
}
.topMenu .topMenu_blk {
  position: relative;
}
@media (max-width: 1199px) {
  .topMenu .topMenu_blk .hamMenus {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .topMenu .topMenu_blk .hamMenus .hamburgar {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .topMenu .topMenu_blk .hamMenus .hamburgar span {
    display: block;
    height: 2px;
    background: #fff;
  }
}
@media (min-width: 1200px) {
  .topMenu .topMenu_blk .hamMenus {
    display: none !important;
  }
}
.topMenu .topMenu_blk .allMenus {
  width: 100%;
}
@media (max-width: 1199px) {
  .topMenu .topMenu_blk .allMenus {
    display: none !important;
  }
  .topMenu .topMenu_blk .allMenus.open {
    display: flex !important;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 40px;
  }
  .topMenu .topMenu_blk .allMenus.open .ant-menu .ant-menu-item {
    width: 100% !important;
    display: block !important;
  }
  .topMenu .topMenu_blk .allMenus.open .ant-menu .ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-overflowed-submenu {
    display: none;
  }
}
.topMenu .ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.topMenu .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
  border-bottom: 4px solid transparent;
  top: 0;
  margin-top: 0;
  font-weight: 300;
}
.topMenu .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:not(:last-child) {
  margin-right: 30px;
}
.topMenu .ant-menu-dark.ant-menu-horizontal > .ant-menu-item .ant-btn-round {
  color: #6809cc;
}
.topMenu .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover,
.topMenu .ant-menu-dark.ant-menu-horizontal > .ant-menu-item-active,
.topMenu .ant-menu-dark.ant-menu-horizontal > .ant-menu-item-open,
.topMenu .ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected,
.topMenu .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #a66ee1;
}
.topMenu img {
  max-height: 19px;
  max-width: 19px;
}
.ant-tabs-nav-container {
  font-size: 1.1em;
}
.ant-tabs-nav .ant-tabs-tab {
  margin: 0 12px 0 0;
  padding: 10px 24px;
}
.profileDropdown.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: #fff;
}
