.tableBtn .ant-btn {
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  width: 32px;
  height: 32px;
  border: 0;
  text-align: center;
  padding: 3px;
  margin: 0 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.countSection_blk p {
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  line-height: 1.2;
}
.countSection_blk p:first-child {
  margin-right: 10px;
  border-right: 1px solid #aaa;
  padding-right: 10px;
}
.profile-upload {
  position: relative;
  display: inline-flex;
}
.profile-upload .custIcon-upload-alt {
  opacity: 0;
  position: absolute;
  width: 32px;
  height: 32px;
  left: 0;
  top: 0;
  background: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 17px;
}
.profile-upload:hover .custIcon-upload-alt {
  opacity: 1;
}
