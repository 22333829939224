.topMenu {
  padding-bottom: 25px;
}
.topMenu .bradcrumTop {
  list-style: none;
  padding: 0;
  margin: 0;
}
.topMenu .bradcrumTop li {
  font-size: 16px;
  list-style: none;
  font-weight: 500;
}
.topMenu .bradcrumTop li a {
  color: #00000080;
  display: block;
  padding: 0 14px 0 0;
}
.topMenu .bradcrumTop li a:hover {
  color: #000;
}
.topMenu .bradcrumTop li .anticon {
  margin-right: 14px;
  color: #000;
  font-size: 12px;
}
.topMenu .bradcrumTop li .anticon svg {
  vertical-align: baseline;
}
.topMenu .ant-menu {
  background: transparent;
  border: 0;
  box-shadow: none;
}
.topMenu .ant-breadcrumb-link {
  cursor: pointer;
}
