.ant-tag {
  margin-right: 0 !important;
  padding: 4px 10px;
  border-radius: 4px;
  height: 22px;
  line-height: 1;
  font-size: 12px;
}
.ant-tag.tagGreen {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
.ant-tag.tagBlue {
  background-color: #93c7e0;
  border-color: #93c7e0;
  color: #fff;
}
