.editprofile-upload {
  position: relative;
  display: inline-flex;
}
.editprofile-upload .uploadIcon {
  width: 100%;
  height: 100%;
  background: #000000a6;
  top: 0;
  position: absolute;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  font-size: 20px;
  color: #fff;
}
.editprofile-upload:hover .uploadIcon {
  opacity: 1;
}
