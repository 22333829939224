.myProfile_blk {
  font-size: 14px;
}
.myProfile_blk .profilelink {
  text-decoration: underline;
  text-transform: capitalize;
}
.myProfile_blk .subscribecard .ant-card-body {
  padding: 25px;
}
.myProfile_blk .subscribecard .ant-card-body h5 .ant-tag {
  vertical-align: bottom;
}
.myProfile_blk .subscribecard p {
  margin-bottom: 0;
}
