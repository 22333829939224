.loginPage {
  min-height: 100vh;
}
.loginPage .loginCard {
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 8px 6px rgba(16, 16, 16, 0.1);
  background-color: #fff;
  overflow: hidden;
}
.loginPage .loginCard .ant-card-body {
  padding: 0;
}
.loginPage .loginCard .ant-card-body .loginFormLeft {
  padding: 30px;
}
@media (max-width: 575px) {
  .loginPage .loginCard .ant-card-body .loginFormLeft {
    padding: 15px;
  }
}
.loginPage .loginCard .ant-card-body .loginFormLeft .loginLogo {
  margin-bottom: 25px;
}
.loginPage .loginCard .ant-card-body .loginFormLeft .loginLogo img {
  max-width: 100%;
  max-height: 40px;
}
@media (max-width: 575px) {
  .loginPage .loginCard .ant-card-body .loginFormLeft .loginLogo {
    margin-bottom: 20px;
  }
}
.loginPage .loginCard .ant-card-body .loginFormLeft .ant-form-item {
  margin-bottom: 0;
}
.loginPage .loginCard .ant-card-body .loginFormLeft .ant-form-item-control {
  position: relative;
  padding-bottom: 25px;
}
.loginPage .loginCard .ant-card-body .loginFormLeft .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error {
  color: #ff4d4f;
  font-size: 12px;
  position: absolute;
  bottom: 4px;
  left: 0;
  min-height: auto;
}
.loginPage .loginCard .ant-card-body .loginFormRight {
  background: #fafafa;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.loginPage .loginCard .ant-card-body .loginFormRight .loginImg {
  height: 477px;
}
.loginPage .loginCard .ant-card-body .loginFormRight .loginImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.loginPage .loginCard .ant-card-body .loginFormRight.signUpRight .loginImg {
  height: 602px;
}
@media (max-width: 575px) {
  .loginPage .loginCard .ant-card-body .loginFormRight {
    position: relative;
  }
  .loginPage .loginCard .ant-card-body .loginFormRight .mapImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.8;
  }
}
.socialbutton button {
  font-size: 16px !important;
}
.forgotPasswordTxt {
  cursor: pointer;
  font-weight: 500;
}
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.confirmPassword .ant-form-item-control-input .ant-form-item-children-icon {
  right: 20px;
}
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 0;
}
.passwordInput {
  position: relative;
}
.passwordInput .ant-input-suffix {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
}
.passwordInput .ant-input-suffix .ant-input-password-icon {
  color: #000;
  font-size: 20px;
}
