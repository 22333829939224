*:focus {
  outline: none !important;
}
.ant-empty {
  width: 100%;
  height: 100%;
}
.anticon {
  vertical-align: 0 !important;
}
.cursorPointer {
  cursor: pointer;
}
.ant-message .anticon {
  top: -3px;
}
thead > tr > th .anticon-filter > svg,
thead > tr > th .ant-table-filter-icon > svg {
  top: 40%;
  margin-top: 0;
}
.ant-table table thead > tr > th,
.ant-table table thead > tr > th:hover {
  font-weight: 500;
  letter-spacing: 0.4px;
  background-color: #000 !important;
  padding: 10px 16px;
  color: #fff;
  font-size: 15px;
}
.ant-table tbody > tr > td {
  padding: 8px 16px !important;
  background: #fff;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #ffffff !important;
}
.ant-table thead > tr > th .anticon-filter,
.ant-table thead > tr > th .ant-table-filter-icon {
  font-size: 1em;
  right: 5px;
}
.ant-table-wrapper body table tbody tr td {
  word-break: break-all;
}
.ant-pagination-prev .ant-pagination-item-link .anticon,
.ant-pagination-next .ant-pagination-item-link .anticon {
  vertical-align: 2px;
}
.primary-text-color {
  color: #000000;
}
.gray-text-color {
  color: #878787;
}
.gray-text-color .custIcon {
  color: #878787;
}
.black-text-color {
  color: #000000 !important;
}
.font-light {
  font-weight: 300 !important;
}
.font-ragular {
  font-weight: 400 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.ant-pagination-item-active a {
  color: #fff !important;
}
.ant-pagination-item-active {
  background: #000000 !important;
  border-color: #000000 !important;
  color: #fff !important;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #fff !important;
}
b,
strong {
  font-weight: 600 !important;
}
img {
  max-width: 100%;
}
.ant-message .ant-message-notice-content .anticon {
  display: flex;
}
.ant-message .ant-message-custom-content.ant-message-warning,
.ant-message .ant-message-custom-content.ant-message-error,
.ant-message .ant-message-custom-content.ant-message-success {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-message .ant-message-custom-content.ant-message-warning .anticon,
.ant-message .ant-message-custom-content.ant-message-error .anticon,
.ant-message .ant-message-custom-content.ant-message-success .anticon {
  top: 0;
}
.ant-tabs .ant-tabs-bar {
  border-bottom: 1px solid rgba(240, 240, 240, 0.7);
}
.react-mapbox-ac-suggestion:hover {
  background-color: #000000 !important;
  color: #fff !important;
}
.ant-avatar-image,
.ant-avatar {
  flex: none;
}
.ant-form-item-control-input {
  z-index: 0;
}
.ant-carousel .slick-dots {
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (max-width: 991px) {
  .ant-tabs-bar {
    display: flex;
    flex-direction: column;
  }
  .ant-tabs-bar .ant-tabs-extra-content {
    margin-bottom: 5px;
  }
}
.googleMapPop .mapboxgl-popup-content .anticon.anticon-left-circle {
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-size: 20px;
  color: #000000;
}
.googleMapPop .mapboxgl-popup-content .anticon.anticon-right-circle {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 20px;
  color: #000000;
}
.googleMapPop .mapboxgl-popup-content .ant-carousel {
  margin-bottom: 30px;
}
.googleMapPop .mapboxgl-popup-content .slick-dots-bottom {
  bottom: 0;
}
@media (max-width: 1024px) {
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    display: none !important;
  }
}
.ant-btn,
a.ant-btn,
button.ant-btn {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.ant-btn.ant-btn-link,
a.ant-btn.ant-btn-link,
button.ant-btn.ant-btn-link {
  padding: 0;
}
.ant-btn.ant-btn-sm,
a.ant-btn.ant-btn-sm,
button.ant-btn.ant-btn-sm {
  font-size: 13px;
}
.ant-btn.ant-btn-lg,
a.ant-btn.ant-btn-lg,
button.ant-btn.ant-btn-lg {
  line-height: 48px;
  padding: 0px 22px;
  height: 48px;
  font-size: 16px;
}
.upload_logo {
  position: relative;
}
.upload_logo .ant-image {
  width: 110px;
  height: 110px;
  position: relative;
}
.upload_logo .ant-image .ant-image-img {
  height: 100%;
  object-fit: cover;
}
.upload_logo .deleteIcon {
  position: absolute;
  bottom: 10px;
  left: 45px;
  background: #fff;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardTimeLine_Sec {
  padding: 25px 0;
}
.cardTimeLine_Sec .timeline_Card {
  max-width: 700px;
  margin: 0 auto;
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item.ant-timeline-item-last {
  padding-bottom: 0;
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item.ant-timeline-item-last .ant-timeline-item-content {
  margin-bottom: 0;
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item.ant-timeline-item-left .ant-timeline-item-content {
  width: calc(50% - 22px);
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item.ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 25px);
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item.ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 22px);
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item .ant-timeline-item-head {
  height: 15px;
  width: 15px;
  margin-left: -6px;
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item .ant-timeline-item-label {
  top: -8px;
  width: calc(50% - 25px);
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item .ant-timeline-item-label .timelineDate {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  background: #000;
  color: #fff;
  display: inline-block;
  padding: 10px 18px;
  line-height: 100%;
  border-radius: 4px;
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item .ant-timeline-item-content {
  margin-bottom: 30px;
  top: -8px;
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item .ant-timeline-item-content .cardTimeLine {
  box-shadow: 0px 0 6px rgba(16, 16, 16, 0.1);
}
.cardTimeLine_Sec .timeline_Card li.ant-timeline-item .ant-timeline-item-content .cardTimeLine .ant-card-body {
  padding: 10px 18px;
}
.editor {
  border: 1px blue solid;
  padding: 1em;
  margin: 1em;
  font-family: 'Open Sans';
  font-size: 90%;
}
div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.tabpanel {
  margin-top: 15px !important;
}
.tabpanel .ant-tabs-nav-wrap {
  overflow: inherit !important;
}
.tabpanel .ant-tabs-nav-wrap::before {
  display: none !important;
}
.tabpanel .ant-tabs-nav::before {
  display: none;
}
.tabpanel .ant-tabs-nav .ant-tabs-tab {
  border: 1px solid #c4c4c4;
  padding: 12px 9px;
  margin: 0;
  font-size: 15px;
  min-width: 106px;
  justify-content: center;
  position: relative;
  color: #292929;
  margin-right: -1px;
}
.tabpanel .ant-tabs-nav .ant-tabs-tab:nth-of-type(4) {
  margin-right: 0;
}
.tabpanel .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
.tabpanel .ant-tabs-nav .ant-tabs-ink-bar {
  display: none !important;
}
.tabcontent {
  padding: 30px 0 20px;
  margin-top: -17px;
}
/*Common start*/
.commonBox.ant-card {
  border: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 6px rgba(16, 16, 16, 0.1);
}
.commonBox.ant-card .ant-card-body {
  padding: 30px;
}
.commonBox.ant-card .ant-card-body h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.commonBox.ant-card .ant-card-head {
  border-bottom: 3px solid #fcfcfc;
  padding: 8px 30px;
}
.commonBox.ant-card .ant-card-head .ant-card-head-title {
  font-size: 24px;
  font-weight: 700;
}
.ant-popover-inner {
  border-radius: 10px;
}
.ant-form-item-label label {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.ant-form-item-control .ant-form-item-control-input-content {
  max-width: 100%;
}
.ant-form-item-control .ant-form-item-control-input-content .ant-input {
  height: 53px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  border: 1px solid #000000;
}
.ant-form-item-control .ant-form-item-control-input-content .ant-picker {
  height: 53px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #000000;
}
.ant-form-item-control .ant-form-item-control-input-content .ant-picker input {
  font-size: 14px;
}
.ant-form-item-control .ant-form-item-control-input-content textarea.ant-input {
  height: 120px;
}
.tabcontent .ant-select.ant-select-single .ant-select-selector {
  border-color: #000000;
  height: 53px;
  padding-left: 20px;
}
.tabcontent .ant-select.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  height: 53px;
}
.tabcontent .ant-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.tabcontent .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: #000000;
  font-size: 14px;
  line-height: 53px;
}
.ant-modal-wrap .ant-modal-content {
  border-radius: 8px;
  box-shadow: 0px 8px 6px rgba(16, 16, 16, 0.1);
}
.ant-modal-wrap .ant-modal-content .ant-modal-close {
  top: 32px;
  right: 30px;
}
.ant-modal-wrap .ant-modal-content .ant-modal-close-x {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #e5e5e5;
  line-height: 24px;
  color: #000;
}
.ant-modal-wrap .ant-modal-content .ant-modal-header {
  padding: 30px;
  border-bottom: 3px solid #fcfcfc;
}
.ant-modal-wrap .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
}
.ant-modal-wrap .ant-modal-content .ant-modal-body {
  padding: 30px;
}
.ant-modal-wrap .ant-modal-content .ant-modal-footer {
  border-top: 0;
  text-align: right;
  padding: 0 30px 30px;
}
.ant-modal-wrap .ant-modal-content .ant-modal-footer .ant-btn {
  min-width: 130px;
}
.ant-modal-wrap .ant-modal-content .ant-modal-footer .ant-btn.btnwidth {
  width: calc(100%/2 - 10px);
}
.ant-modal-wrap .ant-modal-content .ant-modal-footer .ant-btn + .ant-btn {
  margin-left: 20px;
}
.ant-modal-wrap .ant-modal-content .popupNewlabel {
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .ant-modal-wrap .ant-modal {
    max-width: calc(100vw - 40px);
  }
  .ant-modal-wrap .ant-modal .ant-modal-body,
  .ant-modal-wrap .ant-modal .ant-modal-header {
    padding: 15px;
  }
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  object-fit: cover;
}
.ant-popover-inner-content p {
  margin: 0;
}
.ant-typography-edit-content {
  width: 100% !important;
  margin-left: 15px;
}
.nodate {
  background-color: #f0f0f0;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
