html {
  overflow-y: auto;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px !important;
  color: #292929;
}
* {
  font-family: 'Poppins', sans-serif !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root,
.App {
  min-height: 100%;
}
.notSupport .browser {
  max-height: 180px;
}
.notSupport .not-support {
  height: 400px;
}
@media (min-width: 1025px) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px #000000;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
}
