.cardBox {
  border-radius: 8px;
  box-shadow: 0px 8px 6px rgba(16, 16, 16, 0.1);
  background-color: #ffffff;
  border: 0;
}
.cardBox .ant-card-body {
  padding: 30px;
}
.cardBox .ant-card-body h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.cardBox .ant-card-body .cardContent {
  font-weight: 500;
}
.cardBox .ant-card-body .cardButton {
  margin-top: 41px;
}
.cardBox .ant-card-body .cardButton .ant-btn {
  margin: 7px 15px 0 0;
}
.cardBox .ant-card-head {
  border-bottom: 3px solid #fcfcfc;
  padding: 8px 30px;
}
.cardBox .ant-card-head .ant-card-head-title {
  font-size: 24px;
  font-weight: 700;
}
.cardGrey {
  background-color: #fafafa;
  border: 0;
  border-radius: 4px;
}
.cardGrey .ant-card-body {
  padding: 20px;
}
.cardGrey .ant-card-body .storyname {
  font-size: 16px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.cardBtnRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cardBtnRight .hideicon {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none;
}
.cardBtnRight .hideicon .ant-btn {
  transition: all 0.4s ease-in;
  opacity: 0;
}
.cardBtnRight .hideicon.showiconleft {
  visibility: visible;
  pointer-events: inherit;
  width: auto;
  height: auto;
  opacity: 1;
}
.cardBtnRight .hideicon.showiconleft .ant-btn {
  transition: all 0.4s ease-in;
  opacity: 1;
}
.cardBtnRight .showicon .ant-btn svg {
  transform: rotate(90deg);
}
.cardBtnRight .ant-btn {
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  width: 32px;
  height: 32px;
  border: 0;
  text-align: center;
  padding: 3px;
  margin: 0 5px;
  font-size: 1rem;
}
.cardBtnRight .ant-btn .anticon svg {
  vertical-align: top;
}
.cardBtnRight .ant-btn:hover {
  background-color: #000000;
  color: #fff;
}
.cardBtnRight .ant-btn.ant-btn-link {
  background-color: transparent;
  box-shadow: none;
  color: #000000;
  margin: 0;
}
.cardSelect {
  width: 240px;
  margin-right: 10px;
}
.cardSelect .ant-select {
  width: 100%;
}
.cardSelect .ant-select.ant-select-single .ant-select-selector {
  border-color: #000000;
  height: 38px;
  padding-left: 20px;
}
.cardSelect .ant-select.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  height: 38px;
}
.cardSelect .ant-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.cardSelect .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: #000000;
  font-size: 14px;
  line-height: 38px;
}
